<template>
  <div v-if="eulas">
    <v-row no-gutters justify="space-between" align="center">
      <v-btn large depressed tile @click="$refs.editEula.addItem()">
        <v-icon left>mdi-text-box-outline </v-icon>
        Add EULA
      </v-btn>
      <v-col cols="4">
        <search-text-field v-model="search" />
      </v-col>
    </v-row>
    <v-data-table
      @click:row="(eula) => $router.push({ name: 'EulaDetails', params: { id: eula.id }})"
      dense
      :headers="headers"
      :items="eulas"
      :search="search"
      sort-by="updated"
      sort-desc
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.updated`]="{ item }">
        {{ $util.formatDate(item.updated, true) }}
      </template>
      <template #[`item.enabled`]="{ item }">
        <readonly-checkbox :value="item.enabled" />
      </template>
    </v-data-table>
    <create-edit-eula ref="editEula" @item-updated="getEulas" />
  </div>
</template>


<script>
import apiService from '@/services/apiService.js';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';
import CreateEditEula from '@/components/Admin/Dialogs/CreateEditEula.vue';

export default {
  name: 'eula-list',
  components: { ReadonlyCheckbox, CreateEditEula },
  data: () => ({
    eulas: null,
    isLoading: false,
    search: null,
    headers: [
      {
        text: 'Updated',
        value: 'updated',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Enabled',
        value: 'enabled',
      },
    ],
  }),
  methods: {
    async getEulas() {
      this.isLoading = true;
      this.eulas = await apiService.getEulas();
      this.isLoading = false;
    },
  },
  created() {
    this.getEulas();
  },
};
</script>